/* Contact.css */
#contactContainer {
	/* background: url("../img/imgfooter.png") no-repeat center center; */
	border: 10px solid rgba(46, 39, 39, 0.836);
	display: flex;
	flex-direction: row-reverse;
	width: 75%;
	height: auto;
	max-height: 1200px;
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
}

#column1 {
	width: 50%;
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

#column01 {
	width: 50%;
	padding: 30px;
}

#textContact {
	text-align: center;
	margin-bottom: 30px;
	font-size: 1.25em;
	color: white;
	line-height: 1.6rem;
	width: 80%;
	display: block;
	margin: 0 auto;
	font-weight: bold;
	text-shadow: 2px 2px 4px rgba(5, 5, 5, 0.986), 4px 4px 6px rgb(10, 10, 10);
}

#textContact p:nth-child(1) {
	color: white;
	font-weight: bold;
	z-index: 1;
	text-align: center;
	font-size: 1.58rem;
	font-weight: bold;
	/* text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.7),
		4px 4px 6px rgba(255, 255, 255, 0.5); */
	margin-bottom: 9%;
	line-height: 1.8rem;
}

#textContact p:nth-child(2) {
	margin-bottom: 3%;
}

#textContact p:nth-child(3) {
	margin-bottom: 20%;
}

#textContact img {
	width: 180px;
	height: auto;
	object-fit: cover;
	margin-top: 12%;
}

#phoneContact {
	margin-bottom: 10%;
}

.iconContact {
	margin-bottom: 3%;
	box-shadow: 2px 3px 20px 20px rgb(0, 0, 0);
	box-sizing: border-box;
}

.containerFormInput {
	max-width: 670px;
	padding: 30px;
	border-radius: 8px;
	margin: 0 auto;
	box-shadow: 2px 3px 20px 20px rgb(36 31 32);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.containerInput,
#containerTextarea {
	margin-bottom: 20px;
}

.containerInput label,
#containerTextarea label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
	color: white;
	margin-bottom: 15px;
}

.containerInput input,
#containerTextarea textarea {
	width: 95%;
	padding: 10px;
	border-radius: 4px;
	border: none;
	box-sizing: border-box;
	background-color: rgba(46, 39, 39, 0.836);
	resize: none;
	margin-bottom: 10px;
	color: white;
	margin-left: 1%;
}

.containerInput input::placeholder,
#containerTextarea textarea::placeholder {
	color: #aaa;
}

.errors {
	color: #cc36369d;
	font-weight: bold;
}

.errorMessage {
	color: #cc36369d;
	text-align: center;
	margin-top: 20px;
	font-weight: bold;
}

.buttonForm {
	display: block;
	margin: 0 auto;
	margin-top: 6%;
	font-size: 22px;
	font-weight: 700;
	background-color: transparent;
	border: 2px solid white;
	padding: 15px;
	box-shadow: 7px 7px 7px #ffffff62;
	cursor: pointer;
	transition: transform 0.2s;
	margin-bottom: 2%;
	width: 200px;
	color: white;
}

.buttonForm:hover {
	transform: scale(1.2);
	box-shadow: 0px 0px 7px 7px #726c6c;
}
