@media only screen and (max-width: 375px) {
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
	.galerieTexte {
		margin-bottom: 8%;
		font-size: 7vw;
	}
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 391px) and (max-width: 393px) and (-webkit-device-pixel-ratio: 3) {
	#galerieContainer {
		display: grid;
		margin-bottom: 15%;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 394px) and (max-width: 430px) and (-webkit-device-pixel-ratio: 3) {
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		margin-bottom: 15%;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 431px) and (max-width: 539px) {
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
	#galerie {
		margin-top: 15%;
		margin-bottom: 15%;
	}
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
	#galerie {
		margin-top: 15%;
	}
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 769px) and (max-width: 912px) {
	#galerie {
		margin-top: 15%;
		margin-bottom: 15%;
	}
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 913px) and (max-width: 1279px) {
	#galerie {
		margin-top: 15%;
		margin-bottom: 15%;
	}
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo1 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1281px) {
	#galerie {
		margin-top: 15%;
		margin-bottom: 15%;
	}
	#galerieContainer {
		display: grid;
		grid-gap: 10px;
		/* Disposition pour les téléphones */
		grid-template-areas:
			"photo2 photo1 photo1 photo2 photo2 photo2"
			"photo3 photo3 photo3 photo3 photo3 photo3"
			"photo4 photo4 photo4 photo5 photo5 photo5"
			"photo6 photo6 photo6 photo6 photo6 photo6"
			"photo7 photo7 photo7 photo8 photo8 photo8"
			"photo9 photo9 photo9 photo11 photo11 photo11"
			"photo10 photo10 photo10 photo10 photo10 photo10";
	}
}
