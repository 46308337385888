@media only screen and (max-width: 430px) {
}

@media only screen and (min-width: 993px) and (max-width: 1280px) {
	h1 {
		font-size: 60px;
		text-align: center;
		margin-top: 20px;
		position: absolute;
		top: 29%;
	}
	#videoContainer {
		height: 39vh;
	}
}
