#servicesContainer {
	margin-top: -4%;
	margin-left: 30%;
	margin-bottom: 10%;
}
#servicesCard {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 106px;
}

.row {
	display: flex;
	flex-direction: row;
	gap: 106px;
	animation: fadeIn 2s forwards;
}

#services p {
	transform: translate(-50%, -50%);
	position: absolute;
	top: 110%;
	left: 50%;
	z-index: 1;
	text-align: center;
	color: white;
	font-size: 15px; /* Ajustez la taille du texte selon vos préférences */
	text-align: center;
	opacity: 0;
	line-height: 1.5;
	font-style: italic;
	font-weight: 700;
	width: 300px;
}

#servicesContainer i {
	color: white;
	font-size: 24px;
	/* animation: clignotement 1.5s infinite; */
	position: absolute;
	bottom: 10px;
	left: 45%;
	transform: translateX(-50%);
	/* background-color: black; */
	border-radius: 50%;
	padding: 10px;
	z-index: 10;
	animation: bounceArrow 2s infinite;
}

#servicesContainer a {
	transform: translate(-50%, -50%);
	position: absolute;
	top: 110%;
	left: 50%;
	z-index: 1;
	text-align: center;
	color: white;
	font-size: 15px; /* Ajustez la taille du texte selon vos préférences */
	text-align: center;
	opacity: 0;
	line-height: 1.5;
	font-style: italic;
	font-weight: 700;
}

.cards {
	position: relative;
	overflow: hidden;
	width: 350px;
	box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
	transition: box-shadow 0.3s ease-in-out;
	border-radius: 15px; /* Adjust as needed */
}

#servicesContainer .cards:hover {
	cursor: pointer;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
	> h4 {
		position: absolute;
		top: 50px;
		transition-duration: 0.5s;
		text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7), 4px 4px 6px rgba(0, 0, 0, 0.5);
		transition: all 0.3s ease-in-out;
		font-size: 1.3rem;
	}
	> p {
		opacity: 1;
		position: absolute;
		top: 200px;
		transition-duration: 0.5s;
		transition: all 0.3s ease-in-out;
	}
	> i {
		background-color: transparent;
		box-shadow: none;
		position: absolute;
		bottom: 10px; /* Placez l'icône en bas de la carte */
		left: 45%; /* Centre horizontalement */
		animation: bounceArrowDown 2s infinite;
		transition: all 0.5s ease-in-out;
		z-index: 1;
	}
	> a {
		opacity: 1;
		position: absolute;
		top: 330px;
		transition-duration: 0.5s;
		transition: all 0.3s ease-in-out;
		z-index: 11;
	}
}

.cards .imageContainer {
	overflow: hidden;
	transition: transform 0.5s ease-in-out;
	position: relative;
}

.cards .imageContainer img {
	width: 100%;
	height: 460px;
	transition: transform 0.5s ease-in-out;
	max-height: auto;
}

#servicesContainer .cards:hover .imageContainer {
	transform: scale(1.05);
}

h4 {
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	text-align: center;
	color: white;
	font-size: 1.2rem;
	text-align: center;
	font-family: "Arial", sans-serif; /* Changez pour votre police préférée */
	font-weight: bold;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7), 4px 4px 6px rgba(0, 0, 0, 0.5);
}

.cards::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to top,

		rgb(0, 0, 0) 0%,
		rgba(0, 0, 0, 0) 30%
	);
	opacity: 1;
	transition-duration: 0.5s;
	z-index: 1;
}

.cards:hover::before {
	background: linear-gradient(
		to top,

		rgb(0, 0, 0) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	animation: none;
}

@keyframes bounceArrowDown {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0) rotate(180deg);
	}
	40% {
		transform: translateY(-10px) rotate(180deg);
	}
	60% {
		transform: translateY(-5px) rotate(180deg);
	}
}

.presentationService {
	padding-left: 1em;
	font-size: 4vw;
	z-index: 2;
}

@keyframes text-clip {
	from {
		clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
	}
	to {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}

@keyframes outer-left {
	from {
		transform: translateX(50%);
	}
	to {
		transform: none;
	}
}

@keyframes inner-left {
	from {
		transform: translateX(-50%);
	}
	to {
		transform: none;
	}
}

.serviceTexte {
	padding-left: 1em;
	font-size: 4vw;
	color: white;
	animation: fadeIn 2s forwards;
	margin-top: -4%;
	margin-bottom: 6%;
}
#imageFond2 {
	position: absolute;
	z-index: -1;
	width: 1529px;
	height: 958px;
	object-fit: cover;
	transform: scaleX(-1) rotate(265deg);
	left: -280px;
}
