/* Styles de base, hors des media queries, appliqués à tous les écrans */
@media only screen and (max-width: 360px) {
	#videoHome {
		margin-top: -529px;
	}
	.outer-circle {
		margin-right: 0px;
	}
	#navBar {
		gap: 21px;
		height: 61px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}
	h1 {
		font-size: 18px;
		top: 8%;
	}
	#containerPresentation {
		margin-top: 47%;
	}
	h2 {
		font-size: 11px;
		top: 13.5%;
	}
	#videoContainer i {
		font-size: 13px;
		top: 22%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 125%;
		height: 148%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 39%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 81%;
		height: 93%;
		right: 73%;
		top: 13%;
	}
	.small-circle {
		position: absolute;
		width: 19%;
		height: 19%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 100%;
		left: 55%;
	}
	.vertical-wave-separator {
		height: 325vh;
		position: absolute;
		top: 147%;
		right: 53%;
	}

	#containerPresentation2 {
		position: relative;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 50%;
		height: 13%;
		position: absolute;
		margin-left: -26%;
		margin-top: -40%;
		border-radius: 50%;
		top: 13%;
		left: 39%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 50%;
		height: 12%;
		position: absolute;
		margin-left: 77%;
		margin-top: 8%;
		border-radius: 50%;
		top: 7%;
		left: -28%;
	}

	.presentationTexte {
		font-size: 15px;
		padding-left: 1em;
		padding-top: 4em;
		width: 68%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 1em;
		margin-top: 89%;
		width: 68%;
	}
	#containerPointFort {
		margin-top: 177%;
		margin-right: 31%;
		width: 68%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.aProposRow1 div,
	.aProposRow2 div {
		padding: 0px;
		padding-left: 1em;
	}

	.circle-presentation {
		position: absolute;
		width: 9px;
		height: 25px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 55.6%;
		left: 78%;
	}

	.circle2 {
		top: 79%;
		left: 78%;
	}

	.circle3 {
		top: 105%;
		left: 78%;
	}

	.circle4 {
		top: 128%;
		left: 78%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 81%;
		margin-top: 165%;
		position: relative;
	}

	.texteVideo {
		top: -7%;
		left: 9%;
		width: 71%;
		font-size: 16px;
		position: absolute;
	}
	#videoPresentation {
		height: 187px;
		margin-left: 13%;
		box-shadow: -45px -31px 20px 0px #231b20;
		display: block;
	}
}
@media only screen and (min-width: 361px) and (max-width: 375px) {
	#videoHome {
		margin-top: -529px;
	}
	.outer-circle {
		margin-right: 0px;
	}
	#navBar {
		gap: 21px;
		height: 61px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}
	h1 {
		font-size: 18px;
		top: 8%;
	}
	#containerPresentation {
		margin-top: 52%;
	}
	h2 {
		font-size: 11px;
		top: 12.5%;
	}
	#videoContainer i {
		font-size: 13px;
		top: 21%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 125%;
		height: 148%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 39%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 81%;
		height: 93%;
		right: 73%;
		top: 13%;
	}
	.small-circle {
		position: absolute;
		width: 19%;
		height: 19%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 100%;
		left: 55%;
	}
	.vertical-wave-separator {
		height: 354vh;
		position: absolute;
		top: 147%;
		right: 53%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 50%;
		height: 13%;
		position: absolute;
		margin-left: -26%;
		margin-top: -40%;
		border-radius: 50%;
		top: 13%;
		left: 41%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 45%;
		height: 11.5%;
		position: absolute;
		margin-left: 76%;
		margin-top: 8%;
		border-radius: 50%;
		top: 7%;
		left: -22%;
	}

	.presentationTexte {
		font-size: 15px;
		padding-left: 1em;
		padding-top: 4em;
		width: 68%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 1em;
		margin-top: 89%;
		width: 68%;
	}
	#containerPointFort {
		margin-top: 177%;
		margin-right: 31%;
		width: 68%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.aProposRow1 div,
	.aProposRow2 div {
		padding: 0px;
		padding-left: 1em;
	}

	.circle-presentation {
		position: absolute;
		width: 9px;
		height: 25px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 55.6%;
		left: 78%;
	}

	.circle2 {
		top: 76%;
		left: 78%;
	}

	.circle3 {
		top: 99%;
		left: 78%;
	}

	.circle4 {
		top: 121%;
		left: 78%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 81%;
		margin-top: 136%;
		position: relative;
	}

	.texteVideo {
		top: -7%;
		left: 9%;
		width: 71%;
		font-size: 16px;
		position: absolute;
	}
	#videoPresentation {
		height: 187px;
		margin-left: 13%;
		box-shadow: -45px -31px 20px 0px #231b20;
		display: block;
	}
}
@media only screen and (min-width: 376px) and (max-width: 390px) {
	#navBar {
		gap: 30px;
		height: 61px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}
	#videoHome {
		margin-top: -681px;
	}
	h1 {
		font-size: 20px;
		top: 6%;
	}
	h2 {
		font-size: 12px;
		top: 10.5%;
	}
	#videoContainer i {
		font-size: 14px;
		top: 18%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.presentation {
		margin-top: 6%;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 61%;
		height: 72%;
		right: 63%;
		top: 9%;
	}
	.small-circle {
		position: absolute;
		width: 15%;
		height: 15%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 316vh;
		position: absolute;
		top: 110%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 50%;
		height: 14%;
		position: absolute;
		margin-left: 8%;
		margin-top: -40%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 50%;
		height: 14%;
		position: absolute;
		margin-left: 40%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 91%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 180%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 57%;
	}

	.circle2 {
		top: 83%;
	}

	.circle3 {
		top: 110%;
	}

	.circle4 {
		top: 135%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
		margin-top: -11%;
	}

	#containerVideo {
		width: 81%;
		margin-top: 190%;
		position: relative;
	}

	.texteVideo {
		top: -11%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		height: 187px;
		margin-left: 11%;
		box-shadow: -26px -27px 20px 0px #231b20;
		display: block;
		margin-top: 6%;
	}
}
@media only screen and (min-width: 391px) and (max-width: 393px) and (-webkit-device-pixel-ratio: 3) {
	#navBar {
		gap: 25px;
		height: 80px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}
	#videoHome {
		margin-top: -550px;
	}
	h1 {
		font-size: 20px;
		top: 9%;
	}
	h2 {
		font-size: 12px;
		top: 13.5%;
	}
	#videoContainer i {
		font-size: 14px;
		top: 22.5%;
	}
	#aPropos {
		margin-top: -565px;
	}

	.presentation {
		margin-top: 6%;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 61%;
		height: 72%;
		right: 63%;
		top: 9%;
	}
	.small-circle {
		position: absolute;
		width: 15%;
		height: 15%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 340vh;
		position: absolute;
		top: 115%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 50%;
		height: 12%;
		position: absolute;
		margin-left: 8%;
		margin-top: -44.5%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 50%;
		height: 12%;
		position: absolute;
		margin-left: 40%;
		margin-top: 6%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 91%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 180%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 58.1%;
	}

	.circle2 {
		top: 83.1%;
	}

	.circle3 {
		top: 110%;
	}

	.circle4 {
		top: 134.7%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
		margin-top: -11%;
	}

	#containerVideo {
		width: 81%;
		margin-top: 190%;
		position: relative;
	}

	.texteVideo {
		top: -11%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		height: 187px;
		margin-left: 11%;
		box-shadow: 0 0 30px 10px rgba(255, 255, 255, 0.5);
		display: block;
		margin-top: 6%;
		margin-bottom: 15%;
	}
}
@media only screen and (min-width: 394px) and (max-width: 430px) and (-webkit-device-pixel-ratio: 3) {
	#navBar {
		gap: 30px;
		height: 75px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}

	#videoHome {
		margin-top: -580px;
	}
	h1 {
		font-size: 20px;
		top: 9%;
	}
	h2 {
		font-size: 12px;
		top: 13.8%;
	}
	#videoContainer i {
		font-size: 14px;
		top: 22.6%;
	}
	#aPropos {
		margin-top: -570px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 61%;
		height: 72%;
		right: 63%;
		top: 9%;
	}
	.small-circle {
		position: absolute;
		width: 15%;
		height: 15%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 307vh;
		position: absolute;
		top: 110%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 50%;
		height: 14%;
		position: absolute;
		margin-left: 8%;
		margin-top: -40%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 50%;
		height: 14%;
		position: absolute;
		margin-left: 40%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 88%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 165%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 57.9%;
	}

	.circle2 {
		top: 81.9%;
	}

	.circle3 {
		top: 106.7%;
	}

	.circle4 {
		top: 131.1%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
		margin-top: -6%;
	}

	#containerVideo {
		width: 81%;
		margin-top: 190%;
		position: relative;
	}

	.texteVideo {
		top: -75%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		box-shadow: 0 0 30px 10px rgba(255, 255, 255, 0.5);
		display: block;
		margin-top: -20%;
		margin-left: 11.4%;
		justify-content: center;
		align-items: center;
		height: 100%;
		margin-bottom: 15%;
	}
}

@media only screen and (min-width: 431px) and (max-width: 539px) {
	#containerPresentation {
		margin-top: 18%;
	}
	#navBar {
		gap: 30px;
		height: 75px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}
	#videoHome {
		margin-top: -681px;
	}
	h1 {
		font-size: 20px;
		top: 9%;
	}
	h2 {
		font-size: 12px;
		top: 13.5%;
	}
	#videoContainer i {
		font-size: 14px;
		top: 22%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 61%;
		height: 72%;
		right: 63%;
		top: 9%;
	}
	.small-circle {
		position: absolute;
		width: 15%;
		height: 15%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 306vh;
		position: absolute;
		top: 101%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 42%;
		height: 14%;
		position: absolute;
		margin-left: 8%;
		margin-top: -40%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 43%;
		height: 14%;
		position: absolute;
		margin-left: 40%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 88%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 158%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 59%;
	}

	.circle2 {
		top: 79%;
	}

	.circle3 {
		top: 95%;
	}

	.circle4 {
		top: 115%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 82%;
		margin-top: 82%;
		position: relative;
	}

	.texteVideo {
		top: -7%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		height: 255px;
		margin-left: 13%;
		box-shadow: -45px -67px 20px 0px #231b20;
		display: block;
	}
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
	#containerPresentation {
		margin-top: 18%;
	}
	#navBar {
		gap: 30px;
		height: 75px;
	}
	#navBar a {
		font-size: 15px;
	}
	#navBar img {
		width: 50px;
	}
	#videoHome {
		margin-top: -409px;
	}
	h1 {
		font-size: 28px;
		top: 13%;
	}
	h2 {
		font-size: 12.5px;
		top: 19.5%;
	}
	#videoContainer i {
		font-size: 14px;
		top: 32%;
	}
	#aPropos {
		margin-top: -480px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 61%;
		height: 72%;
		right: 63%;
		top: 9%;
	}
	.small-circle {
		position: absolute;
		width: 15%;
		height: 15%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 339vh;
		position: absolute;
		top: 101%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 42%;
		height: 14%;
		position: absolute;
		margin-left: 8%;
		margin-top: -40%;
		border-radius: 50%;
		top: 17%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 43%;
		height: 14%;
		position: absolute;
		margin-left: 40%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 8%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 92%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 158%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
		margin-bottom: 10%;
	}

	.aProposRow1 div,
	.aProposRow2 div {
		padding: 3%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding: 0%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 67.5%;
	}

	.circle1 {
		top: 59%;
	}

	.circle2 {
		top: 79%;
	}

	.circle3 {
		top: 98%;
	}

	.circle4 {
		top: 118%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 82%;
		margin-top: 82%;
		position: relative;
	}

	.texteVideo {
		top: 14%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		height: 255px;
		margin-left: 13%;
		box-shadow: -45px -67px 20px 0px #231b20;
		display: block;
		margin-top: 35%;
		margin-bottom: 15%;
	}
}

/* Petits écrans (tablette en mode portrait) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
	#containerPresentation {
		margin-top: 15%;
	}
	#navBar {
		gap: 65px;
		height: 75px;
	}
	#navBar a {
		font-size: 19px;
	}
	#navBar img {
		width: 68px;
	}
	#videoHome {
		margin-top: -581px;
	}
	h1 {
		font-size: 41px;
		top: 14%;
	}
	h2 {
		font-size: 19px;
		top: 21.5%;
	}
	#videoContainer i {
		font-size: 17px;
		top: 36%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 71%;
		height: 83%;
		right: 63%;
		top: -7%;
	}
	.small-circle {
		position: absolute;
		width: 12%;
		height: 12%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 201vh;
		position: absolute;
		top: 93%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 39%;
		height: 19%;
		position: absolute;
		margin-left: 17%;
		margin-top: -21%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 38%;
		height: 18%;
		position: absolute;
		margin-left: 48%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 69%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 94%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 54%;
	}

	.circle2 {
		top: 73.5%;
	}

	.circle3 {
		top: 90.5%;
	}
	.circle4 {
		top: 109.2%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 82%;
		margin-top: 38%;
		position: relative;
	}

	.texteVideo {
		top: 13%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		height: 356px;
		margin-left: 13%;
		box-shadow: -45px -67px 20px 0px #231b20;
		display: block;
		margin-top: 29%;
		margin-bottom: 15%;
	}

	.serviceTexte {
		padding-left: 1em;
		font-size: 4vw;
		color: white;
		animation: fadeIn 2s forwards;
		margin-top: 0%;
		margin-bottom: 13%;
	}
}

/* Écrans moyens (tablettes en mode paysage) */
@media only screen and (min-width: 769px) and (max-width: 912px) {
	#containerPresentation {
		margin-top: 26%;
	}
	#navBar {
		gap: 65px;
		height: 75px;
	}
	#navBar a {
		font-size: 19px;
	}
	#navBar img {
		width: 68px;
	}
	#videoHome {
		margin-top: -716px;
	}
	h1 {
		font-size: 38px;
		top: 11%;
	}
	h2 {
		font-size: 16px;
		top: 17.5%;
	}
	#videoContainer i {
		font-size: 17px;
		top: 31%;
	}
	#aPropos {
		margin-top: -877px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 71%;
		height: 83%;
		right: 63%;
		top: -7%;
	}
	.small-circle {
		position: absolute;
		width: 12%;
		height: 12%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 20%;
	}
	.vertical-wave-separator {
		height: 183vh;
		position: absolute;
		top: 93%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 39%;
		height: 19%;
		position: absolute;
		margin-left: 17%;
		margin-top: -21%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 38%;
		height: 18%;
		position: absolute;
		margin-left: 48%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 15px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 15px;
		padding-left: 2em;
		margin-top: 69%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 94%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 25px;
	}

	.aProposTexte {
		font-size: 15px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 54.5%;
	}

	.circle2 {
		top: 73.1%;
	}

	.circle3 {
		top: 88.8%;
	}
	.circle4 {
		top: 107.3%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 82%;
		margin-top: 38%;
		position: relative;
	}

	.texteVideo {
		top: 8%;
		left: 9%;
		width: 69%;
		font-size: 20px;
		position: absolute;
	}
	#videoPresentation {
		height: 356px;
		margin-left: 13%;
		box-shadow: -45px -67px 20px 0px #231b20;
		display: block;
		margin-top: 23%;
		margin-bottom: 15%;
	}

	.serviceTexte {
		padding-left: 1em;
		font-size: 4vw;
		color: white;
		animation: fadeIn 2s forwards;
		margin-top: 0%;
		margin-bottom: 13%;
	}
}

/* Grands écrans (ordinateurs de bureau de petite taille) */
@media only screen and (min-width: 913px) and (max-width: 1279px) {
	#containerPresentation {
		margin-top: 70%;
	}
	#navBar {
		gap: 82px;
		height: 75px;
	}
	#navBar a {
		font-size: 19px;
	}
	#navBar img {
		width: 68px;
	}
	#videoHome {
		margin-top: -33px;
	}
	h1 {
		font-size: 47px;
		top: 26%;
	}
	h2 {
		font-size: 19px;
		top: 43.5%;
	}
	#videoContainer i {
		font-size: 28px;
		top: 80%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 71%;
		height: 83%;
		right: 63%;
		top: -15%;
	}
	.small-circle {
		position: absolute;
		width: 12%;
		height: 12%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 21%;
	}
	.vertical-wave-separator {
		height: 210vh;
		position: absolute;
		top: 87%;
		right: 79%;
	}

	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 39%;
		height: 20%;
		position: absolute;
		margin-left: 17%;
		margin-top: -21%;
		border-radius: 50%;
		top: 14%;
		left: 6%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 38%;
		height: 19%;
		position: absolute;
		margin-left: 48%;
		margin-top: 8%;
		border-radius: 50%;
		top: 8%;
		left: 2%;
	}
	.presentationTexte {
		font-size: 20px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 20px;
		padding-left: 2em;
		margin-top: 71%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 116%;
		margin-right: 39%;
		width: 58%;
	}

	.aProposTitre {
		font-size: 30px;
	}

	.aProposTexte {
		font-size: 20px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 65%;
	}

	.circle2 {
		top: 83.3%;
	}

	.circle3 {
		top: 99.5%;
	}
	.circle4 {
		top: 116.5%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 82%;
		margin-top: 50%;
		position: relative;
	}

	.texteVideo {
		top: 8%;
		left: 9%;
		width: 69%;
		font-size: 21px;
		position: absolute;
	}
	#videoPresentation {
		height: 435px;
		margin-left: 10%;
		box-shadow: -45px -67px 20px 0px #231b20;
		display: block;
		margin-top: 18%;
		margin-bottom: 15%;
	}

	.serviceTexte {
		padding-left: 1em;
		font-size: 4vw;
		color: white;
		animation: fadeIn 2s forwards;
		margin-top: 0%;
		margin-bottom: 13%;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1281px) {
	#containerPresentation {
		margin-top: 70%;
	}
	#navBar {
		gap: 82px;
		height: 75px;
	}
	#navBar a {
		font-size: 19px;
	}
	#navBar img {
		width: 68px;
	}
	#videoHome {
		margin-top: 370px;
	}
	h1 {
		font-size: 51px;
		top: 88%;
	}
	h2 {
		font-size: 19px;
		top: 133.5%;
	}
	#videoContainer i {
		font-size: 28px;
		top: 205%;
	}
	#aPropos {
		margin-top: -675px;
	}

	.container {
		width: 35%;
		padding-bottom: 30%;
		position: relative;
		margin-top: 0%;
		margin-right: 0%;
		left: 62%;
		top: 0%;
	}
	.outer-circle {
		width: 100%;
		height: 118%;
		border-radius: 50%;
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.photo-circle {
		width: 71%;
		height: 83%;
		right: 63%;
		top: -15%;
	}
	.small-circle {
		position: absolute;
		width: 12%;
		height: 12%;
		transform: translate(-50%, -50%);
	}
	.small-circle-2 {
		left: 88.2%;
		top: 18%;
	}
	.small-circle-1 {
		top: 91%;
		left: 21%;
	}
	.vertical-wave-separator {
		height: 489vh;
		position: absolute;
		top: 84.1%;
		right: 79%;
	}
	#containerPresentation {
		margin-top: 90%;
	}
	#containerPresentation2 {
		position: relative;

		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.photo-circle2 {
		box-sizing: border-box;
		width: 36%;
		height: 20%;
		position: absolute;
		margin-left: 17%;
		margin-top: -21%;
		border-radius: 50%;
		top: 22%;
		left: 8%;
	}
	.photo-circle3 {
		box-sizing: border-box;
		width: 31%;
		height: 18%;
		position: absolute;
		margin-left: 48%;
		margin-top: 8%;
		border-radius: 50%;
		top: 15%;
		left: 5%;
	}
	.presentationTexte {
		font-size: 20px;
		padding-left: 2em;
		padding-top: 1em;
		width: 57%;
	}
	.presentationTexte2 {
		font-size: 20px;
		padding-left: 2em;
		margin-top: 70%;
		width: 57%;
	}
	#containerPointFort {
		margin-top: 97%;
		margin-right: 35%;
		width: 60%;

		display: flex;
		align-items: end;
	}

	.aProposTitre {
		font-size: 30px;
	}

	.aProposTexte {
		font-size: 20px;
		width: 100%;
		line-height: 2;
	}

	.aProposRow1,
	.aProposRow2 {
		flex-direction: column;
		width: 70%;
	}

	.aProposRow2 div {
		padding: 3px;
	}

	.aProposRow1 div {
		padding: 3px;
	}

	.circle-presentation {
		position: absolute;
		width: 2px;
		height: 2px;
		transform: translate(-50%, -50%);
		left: 69%;
	}

	.circle1 {
		top: 66.5%;
		width: 30px;
		height: 30px;
		left: 69.3%;
	}

	.circle2 {
		top: 81.6%;
		width: 30px;
		height: 30px;
		left: 69.3%;
	}

	.circle3 {
		top: 96.3%;
		width: 30px;
		height: 30px;
		left: 69.3%;
	}
	.circle4 {
		top: 110.3%;
		width: 30px;
		height: 30px;
		left: 69.3%;
	}

	.rowDiv1,
	.rowDiv2 {
		width: 100%;
		display: block;
	}

	#containerVideo {
		width: 82%;
		margin-top: 50%;
		position: relative;
	}

	.texteVideo {
		top: -18%;
		left: 9%;
		width: 69%;
		font-size: 21px;
		position: absolute;
	}
	#videoPresentation {
		height: 435px;
		margin-left: 10%;
		box-shadow: -45px -67px 20px 0px #231b20;
		display: block;
		margin-top: 2%;
		margin-bottom: 15%;
	}

	.serviceTexte {
		padding-left: 1em;
		font-size: 4vw;
		color: white;
		animation: fadeIn 2s forwards;
		margin-top: 0%;
		margin-bottom: 13%;
	}
}

/* Très grands écrans (ordinateurs de bureau de grande taille) */
@media only screen and (min-width: 1282px) and (max-width: 1843px) {
	#aPropos {
		margin-top: -180px;
	}
	#navBar {
		gap: 121px;
	}

	.photo-circle2 {
		width: 400px;
		height: 400px;
		margin-top: 9%;
		top: 179px;
		left: 337px;
	}
	.photo-circle3 {
		width: 250px;
		height: 250px;
		top: 265px;
		left: 293px;
	}
	.presentationTexte2 {
		font-size: 17px;
		left: -410px;
		margin-top: -34%;
	}
	.aProposTitre {
		font-size: 28px;
		margin-bottom: 5px;
	}
	.aProposTexte {
		font-size: 17px;
	}
	.presentationTexte {
		color: white;
		font-size: 17px;
		padding-left: 6em;
	}
	.presentation {
		font-size: 6vw;
	}
	.outer-circle {
		width: 500px;
		height: 500px;
	}
	.photo-circle {
		width: 250px;
		height: 250px;
		right: 35%;
		top: 125px;
	}
	.small-circle-2 {
		right: 255px;
		top: 105px;
	}
	.small-circle-1 {
		top: 502px;
		left: 62%;
	}
	.vertical-wave-separator {
		top: 510px;
		height: 1940px;
		right: 495.2px;
	}
	.texteVideo {
		font-size: 26px;
		left: 48px;
		top: 261px;
		width: 21%;
	}
	#containerVideo {
		margin-top: 4%;
	}
	#containerPointFort {
		width: 70%;
		margin-right: 1%;
		margin-top: 350px;
	}
	.rowDiv1 {
		width: 53%;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		margin-right: 50px;
		margin-left: 20px;
		margin-bottom: 0;
	}
	.rowDiv2 {
		width: 53%;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		margin-left: 20px;
		margin-right: 20px;
	}
	#videoPresentation {
		margin-left: 12%;
		margin-top: 27%;
		width: 1100px;
	}
	#videoHome {
		margin-top: -190px;
	}
	h1 {
		font-size: 66px;
		top: 29%;
	}
	h2 {
		font-size: 23px;
		top: 42%;
	}
	#videoContainer i {
		font-size: 28px;
		top: 69%;
	}
	.circle-presentation {
		position: absolute;
	}

	.circle1 {
		top: 32.5%;
		right: 34.9%;
	}

	.circle2 {
		top: 35.9%;
		right: 34.9%;
	}

	.circle3 {
		top: 39.4%;
		right: 34.9%;
	}
	.circle4 {
		top: 42.8%;
		right: 34.9%;
	}
}

/* Écrans extra larges (écrans larges, TV) */
@media only screen and (min-width: 1844px) and (max-width: 2136px) {
	.photo-circle2 {
		width: 450px;
		height: 450px;
		margin-left: 6%;
		margin-top: 8%;
	}
	.photo-circle3 {
		width: 300px;
		height: 300px;
	}
	.presentationTexte2 {
		font-size: 19px;
		left: -166px;
		margin-top: -34%;
	}
	#videoPresentation {
		margin-left: 19%;
		margin-top: 12%;
		height: 606px;
		width: 60%;
	}
	.aProposTitre {
		font-size: 28px;
	}
	.aProposTexte {
		font-size: 19px;
	}
	.presentationTexte {
		font-size: 19px;
		padding-left: 6em;
	}
	.presentation {
		font-size: 6vw;
	}
	.outer-circle {
		width: 600px;
		height: 600px;
	}
	.photo-circle {
		width: 300px;
		height: 300px;
		right: 32%;
		top: 214px;
	}
	.small-circle-2 {
		right: 290px;
		top: 84px;
	}
	.small-circle-1 {
		top: 626px;
	}
	.vertical-wave-separator {
		top: 651px;
		height: 1355px;
		right: 526px;
	}
	.texteVideo {
		font-size: 28px;
		left: 83px;
		top: 182px;
		width: 22%;
	}
	#containerVideo {
		margin-top: 4%;
	}
	#containerPointFort {
		width: 48%;
	}
	.rowDiv1 {
		width: 47.5%;
	}
	.rowDiv2 {
		width: 49%;
	}
}

/* Écrans ultra larges */
@media only screen and (min-width: 2137px) and (max-width: 2308px) {
	#containerPointFort {
		width: 49%;
		margin-right: 3%;
	}
	.rowDiv1 {
		width: 51%;
	}
	.rowDiv2 {
		width: 52%;
	}
	.aProposTexte {
		font-size: 18px;
	}
	.aProposTitre {
		font-size: 26px;
	}
	.vertical-wave-separator {
		height: 1171px;
		top: 650px;
	}
	.presentationTexte,
	.presentationTexte2 {
		font-size: 18px;
	}
	.presentation {
		font-size: 6vw;
		padding-left: 183px;
	}
	.photo-circle2 {
		width: 550px;
		height: 550px;
	}
	.photo-circle3 {
		width: 350px;
		height: 350px;
	}
	.small-circle-1 {
		top: 615px;
		right: 593px;
	}
	.outer-circle {
		width: 600px;
		height: 600px;
	}
	.photo-circle {
		width: 300px;
		height: 300px;
		right: 30%;
		top: 205px;
	}
	.small-circle-2 {
		right: 320px;
	}
	.aProposRow1,
	.aProposRow2 {
		margin-bottom: 0px;
	}
	.texteVideo {
		left: 279px;
	}
}

@media only screen and (min-width: 2309px) and (max-width: 2469px) {
	#containerPointFort {
		width: 48%;
	}
	.rowDiv1 {
		width: 45%;
	}
	.rowDiv2 {
		width: 48%;
	}
	.aProposTexte {
		font-size: 18px;
	}
	.photo-circle {
		right: 30%;
	}
	.small-circle-1 {
		top: 720px;
	}
	.small-circle-2 {
		right: 369px;
	}
}

/* Écrans super ultra larges */
@media only screen and (min-width: 2561px) {
	/* Styles pour écrans ≥ 2561px */
}
