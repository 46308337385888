@media only screen and (max-width: 360px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 100%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
	}

	.serviceTexte {
		padding-left: 1em;
		font-size: 7vw;
		color: white;
		animation: fadeIn 2s forwards;
		margin-top: -1%;
		margin-bottom: 8%;
	}

	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 7px;
		width: 100%;
		height: 300px; /* Assurez-vous que la hauteur est suffisante pour contenir les cartes */
	}
	.cards {
		width: 170px;
		height: 263px;
	}
	h4 {
		top: 44%;
	}
	#servicesContainer i {
		left: 36%;
	}
	#servicesCard {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		gap: 31px;
		margin: 0 auto; /* Centre le conteneur des cartes */
	}
	#services p {
		position: absolute;
		top: 110%;
		left: 50%;
		font-size: 12px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 174px;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 36%;
			bottom: -5px;
		}

		> h4 {
			top: 50px;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 159px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}
@media only screen and (min-width: 361px) and (max-width: 375px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 100%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
	}

	.serviceTexte {
		padding-left: 1em;
		font-size: 7vw;
		color: white;
		animation: fadeIn 2s forwards;
		margin-top: -1%;
		margin-bottom: 8%;
	}

	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 7px;
		width: 100%;
		height: 300px; /* Assurez-vous que la hauteur est suffisante pour contenir les cartes */
	}
	.cards {
		width: 181px;
		height: 253px;
	}
	h4 {
		top: 44%;
	}

	#servicesContainer i {
		left: 36%;
	}
	#servicesCard {
		width: 90%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		gap: 31px;
		margin: 0 auto; /* Centre le conteneur des cartes */
	}
	#services p {
		position: absolute;
		top: 110%;
		left: 50%;
		font-size: 12px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 156px;
		transition: transform 0.3s ease-in-out;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 36%;
			bottom: -5px;
		}

		> h4 {
			top: 35px;
			font-size: 18px;
			-webkit-text-stroke-width: 0.7px;
			-webkit-text-stroke-color: black;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 149px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 100%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
	}
	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 5px;
		width: 100%;
		height: 300px; /* Assurez-vous que la hauteur est suffisante pour contenir les cartes */
	}
	.cards {
		width: 176px;
		height: 265px;
	}
	h4 {
		top: 44%;
	}
	#servicesContainer i {
		left: 36%;
	}
	#servicesCard {
		width: 90%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		gap: 42px;
		margin: 0 auto; /* Centre le conteneur des cartes */
	}
	#services p {
		left: 50%;
		font-size: 12px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 160px;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 36%;
			bottom: -5px;
		}

		> h4 {
			top: 40px;
			font-size: 19px;
			-webkit-text-stroke-width: 0.7px;
			-webkit-text-stroke-color: black;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 155px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}

	.serviceTexte {
		margin-top: 0%;
		margin-bottom: 10%;
	}
}

@media only screen and (min-width: 391px) and (max-width: 430px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 100%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
		box-sizing: border-box;
	}
	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 7px;
		width: 100%;
		height: 320px; /* Assurez-vous que la hauteur est suffisante pour contenir les cartes */
	}
	.cards {
		width: 176px;
		height: 265px;
		box-sizing: border-box;
	}
	h4 {
		top: 44%;
	}
	#servicesContainer i {
		left: 36%;
	}
	#servicesCard {
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		margin: 0 auto; /* Centre le conteneur des cartes */
		width: 100%;
		gap: 42px;
		box-sizing: border-box;
	}
	#services p {
		left: 50%;
		font-size: 12px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 160px;
		transition: transform 0.3s ease-in-out;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 36%;
			bottom: -5px;
		}

		> h4 {
			top: 40px;
			font-size: 18px;
			-webkit-text-stroke-width: 0.7px;
			-webkit-text-stroke-color: black;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 155px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}

@media only screen and (min-width: 431px) and (max-width: 539px) {
	#servicesContainer {
		margin-top: 0%;
		margin-left: 0%;
		margin-bottom: 26%;
	}
	.row {
		gap: 5px;
	}
	.cards {
		width: 48%;
		height: 295px;
	}
	h4 {
		top: 44%;
	}
	#servicesContainer i {
		left: 38%;
	}
	#servicesCard {
		width: 102%;
		gap: 42px;
	}
	#services p {
		left: 50%;
		font-size: 15px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 214px;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 38%;
			bottom: -5px;
		}

		> h4 {
			top: 41px;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 171px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}
@media only screen and (min-width: 540px) and (max-width: 600px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 95%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
		box-sizing: border-box;
	}
	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 7px;
		width: 100%;
		height: 320px; /* Assurez-vous que la hauteur est suffisante pour contenir les cartes */
	}
	.cards {
		width: 48%;
		height: 295px;
	}
	h4 {
		top: 44%;
	}
	#servicesContainer i {
		left: 38%;
	}
	#servicesCard {
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		margin: 0 auto; /* Centre le conteneur des cartes */
		width: 100%;
		gap: 42px;
		box-sizing: border-box;
	}
	#services p {
		left: 50%;
		font-size: 15px;
		line-height: 1.4;
		font-style: normal;
		font-weight: 100;
		width: 240px;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 38%;
			bottom: -5px;
		}

		> h4 {
			top: 41px;
			-webkit-text-stroke-width: 0.7px;
			-webkit-text-stroke-color: black;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 152px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 100%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
	}
	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 22px;
		width: 100%;
		box-sizing: border-box;
	}
	.cards {
		width: 47%;
		height: 403px;
	}
	h4 {
		top: 44%;
		font-size: 1.8em;
	}
	#servicesContainer i {
		left: 42%;
	}
	#servicesCard {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		gap: 42px;
		margin: 0 auto; /* Centre le conteneur des cartes */
	}
	#services p {
		left: 50%;
		font-size: 15px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 285px;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 42%;
			bottom: -5px;
		}

		> h4 {
			top: 79px;
			font-size: 1.8em;
			-webkit-text-stroke-width: 0.7px;
			-webkit-text-stroke-color: black;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 213px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}

@media only screen and (min-width: 769px) and (max-width: 912px) {
	#servicesContainer {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centre les éléments verticalement */
		width: 100%; /* Prend toute la largeur de l'écran */
		margin: 0 auto; /* Centre le conteneur */
	}
	.row {
		display: flex;
		justify-content: center;
		align-items: center; /* Ajoute l'alignement vertical */
		gap: 22px;
		width: 100%;
		box-sizing: border-box;
	}
	.cards {
		width: 47%;
		height: 403px;
	}
	h4 {
		top: 44%;
		font-size: 1.8em;
	}
	#servicesContainer i {
		left: 42%;
	}
	#servicesCard {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center; /* Centre les cartes dans le conteneur */
		gap: 42px;
		margin: 0 auto; /* Centre le conteneur des cartes */
	}
	#services p {
		left: 50%;
		font-size: 16px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 100;
		width: 285px;
	}

	#servicesContainer .cards:hover {
		> i {
			left: 42%;
			bottom: -5px;
		}

		> h4 {
			top: 79px;
			font-size: 1.8em;
			-webkit-text-stroke-width: 0.7px;
			-webkit-text-stroke-color: black;
		}

		> p {
			opacity: 1;
			position: absolute;
			top: 213px;
			transition-duration: 0.5s;
			transition: all 0.3s ease-in-out;
		}
	}

	#imageFond2 {
		display: none;
	}
}

@media only screen and (min-width: 913px) and (max-width: 1280px) {
	.row {
		gap: 34px;
	}
	.cards {
		height: 317px;
		width: 290px;
	}

	#servicesContainer p {
		font-size: 14px;
		width: 245px;
	}

	#imageFond2 {
		display: none;
	}

	#servicesContainer {
		margin-top: -4%;
		margin-left: 0%;
	}

	#servicesContainer i {
		left: 44%;
	}

	#servicesCard {
		gap: 34px;
	}
	#servicesContainer .cards:hover > p {
		top: 152px;
	}

	#servicesContainer .cards:hover > a {
		top: 244px;
	}
	#servicesContainer .cards:hover > h4 {
		-webkit-text-stroke-width: 0.7px;
		-webkit-text-stroke-color: black;
	}
}

@media only screen and (min-width: 1281px) and (max-width: 1843px) {
	.row {
		gap: 34px;
	}
	.cards {
		height: 400px;
		width: 390px;
	}

	#servicesContainer p {
		font-size: 14px;
		width: 245px;
	}

	#imageFond2 {
		display: none;
	}

	#servicesContainer {
		margin-top: -4%;
		margin-left: 0%;
	}

	#servicesContainer i {
		left: 44%;
	}

	#servicesCard {
		gap: 34px;
	}
	#servicesContainer .cards:hover > p {
		top: 190px;
		font-size: 16px;
		width: 290px;
	}

	#servicesContainer .cards:hover > a {
		top: 310px;
	}
	#servicesContainer .cards:hover > h4 {
		-webkit-text-stroke-width: 0.7px;
		-webkit-text-stroke-color: black;
		top: 14%;
		font-size: 24px;
	}

	h4 {
		font-size: 25px;
		top: 54%;
	}
}
@media only screen and (min-width: 1844px) and (max-width: 2136px) {
	.cards {
		width: 323px;
		height: 410px;
	}
	.row {
		gap: 40px;
	}
	#servicesCard {
		gap: 40px;
	}
	#servicesContainer {
		margin-left: 37%;
	}
}
