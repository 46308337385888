@media only screen and (max-width: 375px) {
	#imageFond1 {
		position: absolute;
		position: absolute;
		bottom: -160px;
		z-index: -1;
		width: 379px;
		height: 320px;
		object-fit: cover;
		transform: rotate(322deg);
		/* right: -92px; */
		left: -131px;
	}
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
	#imageFond1 {
		position: absolute;
		bottom: 76px;
		z-index: -1;
		width: 457px;
		height: 300px;
		object-fit: cover;
		transform: rotate(-26deg);
		left: -110px;
	}
}
@media only screen and (min-width: 391px) and (max-width: 393px) and (-webkit-device-pixel-ratio: 3) {
	#imageFond1 {
		position: absolute;
		bottom: -190px;
		z-index: -1;
		width: 400px;
		height: 350px;
		object-fit: cover;
		transform: rotate(20deg);
		left: -60px;
	}
}

/* @media only screen and (min-width: 391px) and (max-width: 430px) {
	#imageFond1 {
		position: absolute;
		bottom: 47px;
		z-index: -1;
		width: 401px;
		height: 360px;
		object-fit: cover;
		transform: rotate(326deg);
		left: -123px;
	}
} */

@media only screen and (min-width: 394px) and (max-width: 430px) and (-webkit-device-pixel-ratio: 3) {
	#imageFond1 {
		position: absolute;
		bottom: -160px;
		z-index: -1;
		width: 401px;
		height: 360px;
		object-fit: cover;
		transform: rotate(20deg);
		left: -60px;
	}
}

@media only screen and (min-width: 431px) and (max-width: 539px) {
	#imageFond1 {
		position: absolute;
		bottom: -158px;
		z-index: -1;
		width: 1720px;
		height: 549px;
		object-fit: cover;
		transform: rotate(-25deg);
		right: -357px;
	}
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
	#imageFond1 {
		position: absolute;
		bottom: -283px;
		z-index: -1;
		width: 615px;
		height: 489px;
		object-fit: cover;
		transform: rotate(23deg);
		right: 75px;
	}
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
	#imageFond1 {
		position: absolute;
		bottom: -35px;
		z-index: -1;
		width: 937px;
		height: 549px;
		object-fit: cover;
		transform: rotate(-14deg);
		right: 104px;
	}
	#ruban {
		bottom: 268px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 912px) {
	#imageFond1 {
		position: absolute;
		bottom: 218px;
		z-index: -1;
		width: 1176px;
		height: 549px;
		object-fit: cover;
		transform: rotate(-25deg);
		right: -45px;
	}
	#ruban {
		bottom: 268px;
	}
}
@media only screen and (min-width: 912px) and (max-width: 1279px) {
	#imageFond1 {
		position: absolute;
		bottom: -621px;
		z-index: -1;
		width: 663px;
		height: 456px;
		object-fit: cover;
		transform: rotate(-17deg);
		right: 17px;
	}
	#ruban {
		bottom: 268px;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1281px) {
	#ruban {
		bottom: 460px;
	}
	#imageFond1 {
		position: absolute;
		z-index: -1;
		object-fit: cover;
		bottom: -619px;
		width: 621px;
		height: 475px;
		right: 21px;
		transform: rotate(-17deg);
	}
}
@media only screen and (min-width: 1282px) and (max-width: 1843px) {
	#imageFond1 {
		position: absolute;
		bottom: -320px;
		z-index: -1;
		width: 619px;
		height: 415px;
		object-fit: cover;
		transform: rotate(-26deg);
		right: 79px;
	}
	#ruban {
		bottom: 490px;
	}
}

@media only screen and (min-width: 1844px) and (max-width: 2136px) {
	#ruban {
		bottom: 671px;
	}

	#imageFond1 {
		position: absolute;
		bottom: -182px;
		z-index: -1;
		width: 1398px;
		height: 808px;
		object-fit: cover;
		transform: rotate(-25deg);
		right: -216px;
	}

	#textContact img {
		margin-top: 27%;
	}
}

/* @media only screen and (min-width: 912px) and (max-width: 1280px) {
	#imageFond1 {
		bottom: 175px;
		width: 1167px;
		height: 829px;
		right: 144px;
	}
	#ruban {
		bottom: 444px;
	}
} */
