/* Modal base style */
#modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1000; /* Sit on top of other content */
	left: 0;
	top: 0;

	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
	backdrop-filter: blur(10px); /* Blur effect for the background content */
	overflow: auto; /* Adds scroll to the modal if content is too large */
}

/* Modal content container */
.modal-content {
	position: relative;
	max-width: 50%; /* Adjust based on your design preference */
	max-height: 80vh; /* Limits the image height to not surpass the viewport */
	margin: 15vh auto; /* Centers the content with 5vh top margin */
	padding: 20px;
	overflow: hidden;
}

/* Style for the image inside the modal */
.modal-content img,
v {
	width: auto;
	max-width: 100%;
	max-height: 65vh;
	display: block;
	margin: 0 auto;
}

.modal-content p {
	color: white;
	text-align: center;
	font-size: 25px;
}
#textBye {
	margin-top: 4%;
}

/* Style for the close button */
.close {
	position: fixed; /* Fixed position to place it relative to the viewport */
	top: 20px;
	right: 30px;
	color: white;
	font-size: 32px;
	font-weight: bold;
	cursor: pointer;
	z-index: 1001; /* Ensure it's above modal-content */
}
