@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
* {
	--main-bg-color: #140f12;
}

body {
	background-color: var(--main-bg-color);
	font-family: "Kanit", sans-serif;
	position: relative;
	overflow-x: hidden !important;
}

.separatorSection {
	height: 1px;
	background-color: white;
	width: 150px;
	box-shadow: 1px 1px 11px 2px white;
}
