/* modalPicture base style */
#modalPicture {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1000; /* Sit on top of other content */
	left: 0;
	top: 0;

	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
	backdrop-filter: blur(10px); /* Blur effect for the background content */
	overflow: auto; /* Adds scroll to the modalPicture if content is too large */
}

/* modalPicture content container */
.modalPicture-content {
	position: relative;
	max-width: 90%; /* Adjust based on your design preference */
	max-height: 80vh; /* Limits the image height to not surpass the viewport */
	margin: 15vh auto; /* Centers the content with 5vh top margin */
	padding: 20px;
	overflow: hidden;
}

/* Style for the image inside the modalPicture */
.modalPicture-content .imgModalPicture,
#videomodalPicture {
	width: auto;
	max-width: 100%;
	max-height: 65vh;
	display: block;
	margin: 0 auto;
}

/* Style for the close button */
.close {
	position: fixed; /* Fixed position to place it relative to the viewport */
	top: 20px;
	right: 30px;
	color: white;
	font-size: 32px;
	font-weight: bold;
	cursor: pointer;
	z-index: 1001; /* Ensure it's above modalPicture-content */
}

/* Style for the prev and next buttons */
.prev,
.next {
	cursor: pointer;
	position: fixed; /* Fixed position to place it relative to the viewport */
	top: 50%;
	transform: translateY(-50%);
	background: transparent;
	border: none;
	color: white;
	font-size: 50px;
	padding: 20px;
	transition: transform 0.3s ease;
	user-select: none; /* Prevent text selection */
	z-index: 1001; /* Ensure they're above modalPicture-content */
}

.prev:hover,
.next:hover {
	transform: scale(1.2) translateY(-50%); /* Grossit les boutons de 20% */
}

.prev {
	left: 20px;
}

.next {
	right: 20px;
}
