@media only screen and (max-width: 375px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 243vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 132px;
	}
}

@media only screen and (min-width: 361px) and (max-width: 375px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 261vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 132px;
	}
}

@media only screen and (min-width: 376px) and (max-width: 390px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 205vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 139px;
	}
}

@media only screen and (min-width: 391px) and (max-width: 393px) and (-webkit-device-pixel-ratio: 3) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 230vh;
		max-height: 2000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 147px;
	}
}

@media only screen and (min-width: 394px) and (max-width: 430px) and (-webkit-device-pixel-ratio: 3) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 220vh;
		max-height: 2000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 147px;
	}
}

/* @media only screen and (min-width: 391px) and (max-width: 430px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 197vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 147px;
	}
} */

@media only screen and (min-width: 431px) and (max-width: 539px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 231vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 207px;
	}
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 250vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 207px;
	}
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 210vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 207px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 912px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 175vh;
		max-height: 1000%;
	}

	#textContact p:nth-child(1) {
		font-size: 30px;
	}
	#textContact p:nth-child(3) {
		margin-bottom: 17%;
	}

	#textContact {
		font-size: 18px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
	}
	#ruban {
		position: absolute;
		bottom: 285px;
	}
}

@media only screen and (min-width: 913px) and (max-width: 1279px) {
	#contactContainer {
		width: 98%;
		display: block;
		height: 175vh;
		max-height: 1000%;
	}
	#textContact {
		font-size: 17px;
	}
	#column1 {
		width: 100%;
		padding: 5px;
		position: absolute;
	}
	#column01 {
		width: 50%;
		padding: 0px;
		padding-top: 51px;
		padding-bottom: 51px;
	}
	#ruban {
		position: absolute;
		bottom: 347px;
	}
}

@media only screen and (min-width: 1280px) and (max-width: 1281px) {
	#contactContainer {
		width: 90%;
	}
	#textContact p:nth-child(1) {
		font-size: 1.3rem;
	}

	#textContact p:nth-child(2) {
		font-size: 1rem;
		margin-bottom: 5%;
	}

	#textContact p:nth-child(3) {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 1282px) and (max-width: 1843px) {
	#contactContainer {
		width: 90%;
	}
}
