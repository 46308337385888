#aPropos {
	display: flex;
	flex-direction: column;
	min-height: 130vh;
}

#containerPresentation {
	margin-top: 5%;
}

/* styles.css */

.presentation {
	padding-left: 1em;
	font-size: 8vw;
	z-index: 2;
}

@keyframes text-clip {
	from {
		clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
	}
	to {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}

@keyframes outer-left {
	from {
		transform: translateX(50%);
	}
	to {
		transform: none;
	}
}

@keyframes inner-left {
	from {
		transform: translateX(-50%);
	}
	to {
		transform: none;
	}
}

.prenom,
.nom {
	animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.containerNom {
	display: inline-block;
	animation: inner-left 1s 1s ease both;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
}

.prenom h3 {
	display: inline-block;
	animation: inner-left 1s 1s ease both,
		text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.nom h3 {
	animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.presentation {
	animation: outer-left 1s 1s ease both;
}

#imageProfil img {
	width: 50%;
}

.container {
	width: 100%;
	position: relative;
	opacity: 0; /* Initialise l'opacité à 0 */
	animation: fadeIn 2s forwards;
	z-index: 10;
}

.outer-circle {
	width: 700px;
	height: 700px;
	border: 2px solid white;
	border-radius: 50%;
	float: right;
	margin-right: 10%;
	margin-top: 50px;
	/* animation: rotateOuterCircle 20s linear infinite; */
}

.photo-circle {
	width: 400px;
	height: 400px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	position: absolute;
	right: 28%;
	top: 205px;
	object-fit: cover;
}

.small-circle {
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #1e3713; /* Couleur du petit cercle */
	/* animation: rotateSmallCircle 10s linear infinite alternate;  */
}

.small-circle-1 {
	/* top: 82%;
  left: 81%; */
	top: 715px;
	left: 71%;
	z-index: 3;
}

.small-circle-2 {
	/* top: -4%;
  left: 50%; */
	left: 83%;
	top: 86px;
}

@keyframes rotateOuterCircle {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes rotateSmallCircle {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.photo-circle img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.presentationChiffre {
	color: white;
	font-size: 100px;
	line-height: 2;
	text-align: justify;
	width: 30%;
	padding-left: 11em;
	padding-top: 6em;
	/* animation: fadeIn 2s forwards; */
}

#containerVideo {
	width: 100%;
}

#backgroundVideo {
	display: flex;
	margin-left: 20%;
	width: 47%;
	height: 677px;
	background-color: #1b1519;
}
#videoPresentation {
	display: flex;
	margin-left: 25%;
	margin-top: 6%;
	margin-bottom: 7%;
	height: 681px;
	box-shadow: -126px -109px 20px 0px #231b20;
}

#containerPresentation2 {
	margin-bottom: 5%;
	z-index: 10;
}
.photo-circle2 {
	width: 600px;
	height: 600px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	object-fit: cover;
	margin-left: 3%;
	animation: fadeIn 2s forwards;
	margin-top: 3%;
}

.photo-circle3 {
	width: 400px;
	height: 400px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	object-fit: cover;
	border: 20px solid #130f12;
	/* position: absolute; */
	top: 145px;
	left: -155px;
	animation: fadeIn 2s forwards;
}

.presentationTexte {
	color: white;
	font-size: 20px;
	line-height: 2;
	text-align: justify;
	width: 36%;
	padding-left: 11em;
	padding-top: 6em;
	animation: fadeIn 2s forwards;
}

.presentationTexte2 {
	color: white;
	font-size: 20px;
	line-height: 2;
	text-align: justify;
	width: 36%;
	padding-left: 35em;
	/* position: absolute; */
	animation: fadeIn 2s forwards;
	position: absolute;
	/* top: 1px; */
	/* top: 200%; */
	/* left: -159px; */
	/* margin-bottom: 83%; */
	margin-top: -30%;
}

#containerPointFort {
	width: 51%;
	height: 800px;
	border: solid 7px rgba(255, 0, 0, 0);
	float: right;
	margin-top: 10%;
	margin-right: 3%;
	display: flex;
	flex-direction: column;
}

.aProposRow1,
.aProposRow2 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	align-items: center;
	text-align: center;
	animation: fadeIn 2s forwards;
}

.aProposTitre {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 10px;
	color: white;
}
.aProposTexte {
	font-size: 20px;
	margin-bottom: 10px;
	color: white;
	line-height: 1.6;
	text-align: justify;
}

.aProposRow1 div,
.aProposRow2 div {
	padding: 15px;
}

.vertical-wave-separator {
	width: 2px;
	height: 1306px;
	position: absolute;
	background-color: rgb(255, 255, 255);
	top: 758px;
	right: 28%;
}

.circle-presentation {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #1e3713;
}

.circle1 {
	top: 226%;
}

.circle2 {
	top: 247%;
}

.circle3 {
	top: 267%;
}

.circle4 {
	top: 288%;
}
.texteVideo {
	font-size: 25px;
	color: white;
	position: relative;
	top: 101px;
	left: 376px;
	width: 14%;
}

.rowDiv1 {
	width: 45%;
}
.rowDiv2 {
	width: 42%;
}
