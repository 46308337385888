#navBar {
  width: 100%;
  height: 100px;
  background-color: #20202047;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  z-index: 1;
}

#navBar a {
  color: white;
  font-size: 20px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}
#navBar a:hover {
  transform: scale(1.2);
}
#navBar a::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background-color: white;
  transition: width 0.4s;
  position: absolute;
  bottom: -15px;
}

#navBar a:hover::after {
  width: 100%;
}

#navBar img {
  width: 91px;
}

#videoHome {
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 0;
}

#videoContainer {
  position: relative;
  width: 100%;
  height: 99vh;
  object-fit: cover;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
}

h1 {
  font-size: 60px;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 38%;
  z-index: 1;
  color: white;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.7),
    4px 4px 6px rgba(255, 255, 255, 0.5);
}
h2 {
  color: white;
  font-size: 23px;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  z-index: 1;
  line-height: 1.3;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
}

#videoContainer i {
  font-size: 35px;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 89%;
  z-index: 10;
  color: white;
  /* animation: clignotement 1.5s infinite; */
  animation: bounceArrow 2s infinite;
}

@keyframes bounceArrow {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes clignotement {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
  /* Styles for phones */
}

/* Small devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  /* Styles for tablets */
}

/* Medium devices (desktops) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* Styles for desktops */
}

/* Large devices (large desktops) */
@media only screen and (min-width: 1025px) {
  /* h1 {
    margin-top: 20px;
    top: 450px;
  }

  h2 {
    top: 570px;
  }

  #videoContainer i {
    top: 900px;
  } */
}
