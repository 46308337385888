@media only screen and (max-width: 375px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin: 14vh auto;
		margin-top: 10%;
	}
	.prev,
	.next {
		top: 94.5%;
		font-size: 20px;
	}
	.next {
		right: 30px;
	}

	.prev {
		left: 30px;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 376px) and (max-width: 430px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin: 27vh auto;
		margin-top: 20%;
	}
	.prev,
	.next {
		top: 90.5%;
		font-size: 30px;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 431px) and (max-width: 539px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin: 27vh auto;
	}
	.prev,
	.next {
		top: 34%;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin-top: 10%;
		max-width: 70%;
		max-height: 100%;
	}

	.next {
		right: 20px;
	}

	.prev {
		left: 20px;
	}

	.prev,
	.next {
		top: 40%;
	}

	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
	#modalPicture {
		width: 99%;
	}
	.modalPicture-content {
		margin: 27vh auto;
		margin-top: 10%;
		max-width: 72%;
		max-height: 100%;
	}
	.next {
		right: 40px;
	}

	.prev {
		left: 40px;
	}

	.prev,
	.next {
		top: 60%;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 912px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin: 27vh auto;
		margin-top: 10%;
		max-width: 71%;
		max-height: 99%;
	}
	.next {
		right: 40px;
	}

	.prev {
		left: 40px;
	}

	.prev,
	.next {
		top: 60%;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 913px) and (max-width: 1280px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin: 27vh auto;
		margin-top: 10%;
		max-width: 71%;
		max-height: 100%;
	}
	.next {
		right: 40px;
	}

	.prev {
		left: 40px;
	}

	.prev,
	.next {
		top: 60%;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}

@media only screen and (min-width: 1281px) and (max-width: 1843px) {
	#modalPicture {
		width: 100%;
	}
	.modalPicture-content {
		margin: 27vh auto;
		margin-top: 10%;
		max-width: 71%;
		max-height: 200%;
		width: 600px;
	}
	.next {
		right: 40px;
	}

	.prev {
		left: 40px;
	}

	.prev,
	.next {
		top: 60%;
	}
	.close {
		top: 0px;
		right: 30px;
		font-size: 56px;
	}
}
