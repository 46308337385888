#galerieContainer {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(
		5,
		20vh
	); /* Réglez la hauteur des lignes en fonction de la hauteur de l'écran */
	grid-gap: 10px;
	padding: 10px;
}
#galerie {
	margin-bottom: 7%;
}

.galerieTexte {
	padding-left: 1em;
	font-size: 4vw;
	color: white;
	animation: fadeIn 2s forwards;
	margin-top: 4%;
	margin-bottom: 5%;
}

.photo {
	overflow: hidden;
	position: relative;
}

.photo img,
video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
}

/* Placement spécifique des images selon la grille */

/* Définition des zones de la grille pour chaque photo */
#galerieContainer {
	grid-template-areas:
		"photo1 photo1 photo3 photo4 photo2 photo6"
		"photo1 photo1 photo3 photo4 photo2 photo6"
		"photo7 photo8 photo3 photo4 photo5 photo6"
		"photo7 photo8 photo9 photo9 photo10 photo11"
		"photo7 photo8 photo9 photo9 photo10 photo11";
}

.no-scroll {
	overflow: hidden;
	height: 100%; /* Pour les mobiles où l'overflow hidden seul ne suffit pas */
}
