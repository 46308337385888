#footer {
  max-height: 100vh;
  margin-top: 4%;
}

#footer div {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  margin-top: 4%;
}

#imgFooter {
  margin-top: 5%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

#ruban {
  position: absolute;
  bottom: 923px;
  z-index: 1000;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-x: auto;
}

#ruban span:nth-child(1) {
  cursor: pointer;
}
#imageFond1 {
  position: absolute;
  bottom: 329px;
  z-index: -1;
  width: 1596px;
  height: 859px;
  object-fit: cover;
  transform: rotate(-25deg);
  right: -67px;
}
